<template>
    <div>
        <div>
            <img src="@/assets/imgs/about_us1.png" style="width: 100%">
            <img src="@/assets/imgs/about_us2.png" style="width: 100%">
            <div class="about-us6">
                <img src="@/assets/imgs/about_us6.png" style="width: 100%;">
                <div class="about-us6-Text">
                    <div>爱复盘是中国首款一站式、多平台、专业直播内容复盘分析工具。</div>
                    <div>作为第一款由主播、操盘手、风控、互联网产品、开发共同打造的内容复盘分析工具，爱复盘提供了自动录屏、自动分析、语速分析、违规风险提示、对比分析、在线分析、播前分析、违规风险管控、本地词库、专家服务等一站式直播内容智能复盘分析服务，能够帮助直播公司和团队大幅简化主播、运营、剪辑、编辑等核心岗位的工作流程，降低直播间命中违禁词的频率、提高主播和运营的复盘能力、异地协同能力、直播素材和复盘知识沉淀的能力。</div>
                    <div>爱复盘的基础功能版块，提供了从直播录制、视频多种清晰度选择、本地视频存储、转译视频成文字等一站式服务。智能复盘分析板块，帮助直播团队更好的理解和定位直播的关键节点和关键行为，不仅可以和<span>优秀</span>直播间对比，还可以同一个直播间对比，以提升直播间的核心数据，从而获得更高的流量和转化。</div>
                    <div>风险管控板块，由专业的风控专家和顶级操盘手构建，敏感词准确率达到70%，覆盖了电商、知识付费、本地生活、娱乐PK、大健康等<span>1200多个细分行业和1000种违规细分场景</span>。</div>
                    <div>既可以开播前进行敏感词分析，还可以下播后分析，为新老主播、运营快速定位违规风险点，企业招聘和培训、提供了强有力的参考。</div>
                    <div>定制化服务板块上，我们提供了本地词库功能和专家服务，为直播公司提供更个性化的运营拓展空间。</div>
                    <div>爱复盘是一款有核心方法论的智能复盘分析工具，公司创始人和创始团队在零基础主播培养、赛道快速复制，直播玩法创新上，均具备<span>较高</span>行业知名度。</div>
                    <div>在创办5年的时间里，打造过30多个万人在线直播间，10多个直播间起号一周内就达到10万+场观，在多个细分赛道里短时间内做到行业第一。</div>
                    <div>我们结合内部运营方法论，<span>又提炼了</span>100+头部直播公司的操盘手和500个万人在线直播间的复盘方法，让复盘不再流于形式和表面，从而快速的提升主播的直播能力和运营的分析总结能力，帮助直播公司获取更多的流量和转化</div>
                    <div>在后直播时代，比拼的是专业能力和精细化运营能力，提升复盘效率和复盘效果，已经是决定直播生死的基本生命线。</div>
                    <div>直播行业的从业者，唯有提高人效、提升专业度，才能在瞬息万变的直播时代更好的生存和发展！<br>
                        <span>数据只是结果，内容才是核心，复盘解决根本！做直播，向复盘，要未来！</span>
                    </div>
                </div>
            </div>
            <img src="@/assets/imgs/about_us3.png" style="width: 100%">
            <img src="@/assets/imgs/about_us4.png" style="width: 100%">
            <img src="@/assets/imgs/about_us5.png" style="width: 100%">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>
<style scoped>
    .msg{
        background-color: #F6F7F9;
        display: flex;
        flex-direction: column; 
        align-items: center; 
        justify-content: center;
        padding: 24px 0px;
        color: #666666;
        font-size: 14px;
    }

    .about-us6{
        position: relative;
        display: flex;
    }

    .about-us6-Text{
        position: absolute;
        top: 26.5%;
        left: 20.5%;
        width: 60%;
        color: #2B2E33;
        font-size: 0.95vw;
        height: 61%;
        overflow-y: auto;
    }

    .about-us6-Text div{
        margin-bottom: 15px;
        line-height: 1.8
    }

    .about-us6-Text span{
        color: red;
    }

    ::v-deep .about-us6-Text::-webkit-scrollbar {
        width: 17px;
    }

    ::v-deep .about-us6-Text::-webkit-scrollbar-thumb {
        background: #ddd;
        border-radius: 8px;
    }
</style>